
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { mapGetters, mapActions } from 'vuex';
import { restApi } from '@/components/mixins/restApi';
import { DEFINE_FAILURE_FLOW_MODAL_KEY } from '../constants';
import { showMessage } from '@/components/mixins/showMessage';
import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect';

import Modal from './Modal.vue';
import InfoBox from '@/components/InfoBox.vue';
import SelectTree from '@/components/SelectTree.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import AliareInput from '@/components/AliareInput.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import SwitchAliare from '@/components/SwitchAliare.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import { workflowPermission } from '@/components/mixins/permissions/workflow';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import { setupHeaderAliareTenant } from '@/config/axiosConfig';


import moment from 'moment';



export default mixins(
	restApi,
	showMessage,
	workflowHelpers,
	workflowPermission,
).extend({
	name: 'DefineExecutionsParameters',
	components: {
		Modal,
		InfoBox,
		SelectTree,
		SwitchAliare,
		ModalButtonCancel,
		AliareInput,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	props: {
		dataModal: {
			type: Object,
		},
		mode: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			validation: false,
			isLoading: false,
			showAlert: {
				mode: '',
				active: false,
			},
			timezoneIsFocused: false,
			showTimezoneSelect: false,
			timezonesList: [],
			timezoneObject: '',
			defaultValues: {
				timezone: 'America/Fortaleza',
			},
			modalBus: new Vue(),
			DEFINE_FAILURE_FLOW_MODAL_KEY,
			currentProject: null,
			currentWorkflow: null,
			selectedProject: null,
			selectedWorkflow: null,
			prevWorkflowError: JSON.parse(JSON.stringify(this.dataModal)),
			optionsProject: null,
			optionsWorkflow: [],
			keepEditing: true,
			tenantId: WebStorage.getItem("tenantId"),
			formWorkflowSettings: {
				automatico: false,
				multipleExecution: false,
				timezone: {},
				executionTimeout: '',
			},
			showTimeoutMessageError: false,
			timeoutMessageError: 'O tempo deve estar entre 1 e 60 minutos.',
		};
	},
	async created() {
		this.isLoading = true;
		this.formWorkflowSettings.automatico = JSON.parse(JSON.stringify(this.typeExecutionWorkflow));
		this.formWorkflowSettings.multipleExecution = JSON.parse(JSON.stringify(this.workflowSettings.multipleExecution || false));
		this.formWorkflowSettings.timezone = JSON.parse(JSON.stringify(this.workflowSettings.timezone));
		this.initTimeout();
		await setupHeaderAliareTenant(this.tenantId);
		await this.initTimezone();

		if(this.mode == 'changeFailure') {
			await this.getWorkflowFailureId();
		}

		this.isLoading = false;
	},
	computed: {
		...mapGetters({
			projectData: 'projectData',
			typeExecutionWorkflow: 'isActive',
			workflowSettings: 'workflowSettings',
			hasTrigger: 'hasTriggerNode',
		}),

		keepText() {
			if (this.showAlert.mode == 'resetFailWorkflow') {
				return 'Manter Fluxo de Falha';
			} else if (this.showAlert.mode == 'cancelConfigSettings') {
				return 'Manter parametrização'
			}
		},

		cancelText() {
			if (this.showAlert.mode == 'resetFailWorkflow') {
				return this.$locale.baseText('workflowDetails.patchFlowFail.removeFailureFlow');
			} else if (this.showAlert.mode == 'cancelConfigSettings') {
				return 'Cancelar parametrização'
			}
		},

		titleAlertModal() {
			if (this.showAlert.mode == 'resetFailWorkflow') {
				return this.$locale.baseText('workflowDetails.patchFlowFail.removeFailureFlow');
			} else if (this.showAlert.mode == 'cancelConfigSettings') {
				return 'Cancelar Parametrização'
			}
		},
		canSaveSettings() {
			if ((this.hasChangeOnTypeExecution || this.hasChangeOnMultipleExecution || this.hasChangeOnTimezone || this.hasChangeOnTimeout || this.hasChangeOnErrorWorkflow) && !this.showTimeoutMessageError) {
				return true;
			}
			return;
		},

		hasChangeOnTimezone() {
			if (this.workflowSettings.timezone == this.formWorkflowSettings.timezone.key) {
				return;
			} else {
				return true
			}
		},

		hasChangeOnErrorWorkflow() {
			if (this.prevWorkflowError.selectedWorkflowId ==  this.selectedWorkflow?.id) {
				return;
			} else {
				return true;
			}
		},

		hasChangeOnTimeout() {
			if (this.$store.getters.executionTimeout == parseFloat(this.formWorkflowSettings.executionTimeout) * 60 || (this.$store.getters.executionTimeout == -1 && this.formWorkflowSettings.executionTimeout == '')) {
				return;
			} else {
				return true;
			}
		},

		hasChangeOnTypeExecution() {
			if (this.typeExecutionWorkflow == this.formWorkflowSettings.automatico) {
				return;
			} else {
				return true
			}
		},


		hasChangeOnMultipleExecution() {
			if (this.workflowSettings.multipleExecution == this.formWorkflowSettings.multipleExecution) {
				return;
			} else {
				return true
			}
		},

		typeExecution() {
			if (this.typeExecutionWorkflow) {
				return 'Automático'
			} else {
				return 'Manual'
			}
		},


		alertNotificationDescription() {
			if (this.showAlert.mode == 'resetFailWorkflow') {
				return this.$locale.baseText(`Ao parar de usar o <b>Fluxo de Falha</b>, caso a execução do fluxo que você está criando resulte em <b>Falha</b>, não haverá o acionamento de um <b>Fluxo de Falha</b>. O que deseja fazer?`);
			} else if (this.showAlert.mode == 'cancelConfigSettings') {
				return 'Todas as parametrizações que estão sendo feitas serão excluídas e você não poderá recuperá-las. O que você deseja fazer?'
			}
		},
		pageTitleMode() {
			return 'Parâmetros de Execução';
		},
		saveTitleMode() {
			if (this.mode === 'changeFailure') {
				return 'Salvar Alterações';
			} else {
				return 'Concluir';
			}
		},
		isNewWorkflow(): Boolean {
			return this.workflowId === '__EMPTY__' ? true : false;
		},
		workflowId(): number {
			return this.$store.getters.workflowId;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		...mapActions('workflows', ['getWorkflowsFails', 'patchWorkflowFail', 'getWorkflows']),

        async loadOptions({ action }) {
            if (action === LOAD_ROOT_OPTIONS) {
                await this.getProjects();
            }
        },
		closeDialog() {
			this.keepEditing = false;
			this.modalBus.$emit('close');
		},
		verifyHasUnsavedChanges() {
			if (this.canSaveSettings && this.keepEditing) {
				this.openAlertModal({mode: 'cancelConfigSettings', active: true})
				return false;
			} else {
				return true;
			}
		},
		removeErrorWorkflow() {
			this.selectedProject = null;
			this.optionsWorkflow = [];
			this.selectedWorkflow = '';
		},
		removeExecutionTimeout() {
			this.formWorkflowSettings.executionTimeout = '';
			this.showTimeoutMessageError = false;
		},
		verifyValueTimeout() {
			if (this.formWorkflowSettings.executionTimeout <= 60 && this.formWorkflowSettings.executionTimeout >= 1) {
				this.showTimeoutMessageError = false;
				return;
			};

			this.showTimeoutMessageError = true;
		},
		openTimezoneSelect() {
			this.showTimezoneSelect = true;
			this.$refs.selectN8n.$el.click()
		},
		async initTimeout() {

			const tempTimeout = JSON.parse(JSON.stringify(this.$store.getters.executionTimeout));

			if (tempTimeout == -1) {
				this.formWorkflowSettings.executionTimeout = '';
			} else {
				this.formWorkflowSettings.executionTimeout = (tempTimeout / 60) + '';
			}
		},
		async initTimezone() {
			this.defaultValues.timezone = this.$store.getters.timezone;
			await this.loadTimezones();

			const formWorkflowSettings = JSON.parse(JSON.stringify(this.$store.getters.workflowSettings));

			if (formWorkflowSettings.timezone === undefined || formWorkflowSettings.timezone === 'padrao') {
				formWorkflowSettings.timezone = {
					key: 'padrao',
					value: 'Padrão - (GMT-03:00) Fortaleza',
				};
			} else {
				formWorkflowSettings.timezone = {
					value: this.timezoneObject[formWorkflowSettings.timezone],
					key: formWorkflowSettings.timezone,
				}
			}

			Vue.set(this, 'formWorkflowSettings', {
				...this.formWorkflowSettings,
				timezone: formWorkflowSettings.timezone
			});
		},
		async loadTimezones () {
			if (this.timezonesList.length !== 0) {
				// Data got already loaded
				return;
			}

			const timezones = await this.restApi().getTimezones();
			this.timezoneObject = timezones;
			let defaultTimezoneValue = timezones[this.defaultValues.timezone] as string | undefined;
			if (defaultTimezoneValue === undefined) {
				defaultTimezoneValue = this.$locale.baseText('workflowSettings.defaultTimezoneNotValid');
			}

			this.timezonesList.push({
				key: 'padrao',
				value: this.$locale.baseText(
					'workflowSettings.defaultTimezone',
					{ interpolate: { defaultTimezoneValue } },
				),
			});
			for (const timezone of Object.keys(timezones)) {
				this.timezonesList.push({
					key: timezone,
					value: timezones[timezone] as string,
				});
			}
		},
		formatMomentToString(timezone) {
			if (typeof timezone === 'object') {
				timezone = timezone.value;
			}
			const GMT = timezone.slice(4, 10)
			moment.locale('pt');
			return moment().utcOffset(GMT).format('LLLL');
		},
		hideTimezoneSelect() {
			this.showTimezoneSelect = false;
		},
		timezoneOnFocus() {
			this.timezoneIsFocused = !this.timezoneIsFocused;
		},
		async saveSettingsToWorkflow() {
			this.isLoading = true;
			try {
				if (this.formWorkflowSettings.executionTimeout == '') {
					this.formWorkflowSettings.executionTimeout = -1;
				}
				let errorWorkflowId;
				if (this.hasChangeOnErrorWorkflow) {
					errorWorkflowId = this.selectedWorkflow.id;
				} else {
					errorWorkflowId = this.dataModal.workflowFalhaId;
				}
				if (this.hasChangeOnTypeExecution) {
					this.$store.commit('setActive', this.formWorkflowSettings.automatico);
				}
				if (this.hasChangeOnTimeout) {
					let timeoutData;
					if (this.formWorkflowSettings.executionTimeout == -1) {
						timeoutData = -1;
					} else {
						timeoutData = parseFloat(this.formWorkflowSettings.executionTimeout) * 60;
					}
					this.$store.commit('setExecutionTimeout', timeoutData);
				}
				if (this.hasChangeOnTimezone) {
					this.$store.commit('setTimezone', this.formWorkflowSettings.timezone.key || {});
				}


				this.$store.commit('setWorkflowSettings', {
					timezone: this.formWorkflowSettings.timezone.key,
					executionTimeout: this.formWorkflowSettings.executionTimeout == -1 ? -1 : parseFloat(this.formWorkflowSettings.executionTimeout * 60),
					errorWorkflow: errorWorkflowId,
					multipleExecution: this.formWorkflowSettings.multipleExecution,
				} || {});


				const response = await this.saveCurrentWorkflow();


				this.$store.commit('activeAlert', {
					message: 'As parametrizações de execução do fluxo foram salvas com sucesso',
				});
				this.closeDialog();
			} catch(e) {
				this.$store.commit('activeAlert', {
					message: 'Não foi possível salvar as parametrizações de execução do fluxo, tente novamente!',
					status: 'error',
				});
			}
			this.isLoading = false;
		},

		openAlertModal(data: {mode: string, active: boolean}) {
			this.showAlert.active = data.active;
			this.showAlert.mode = data.mode;
		},

		async onSelect(type) {
			this.selectedProject = type;
			this.selectedWorkflow = null;

			if(this.selectedProject.id) {
				await this.getFails(this.selectedProject.id);
			}
		},
		onSelectWorkflow(type) {
			this.selectedWorkflow = type;
		},
		async getFails(projetoId) {
			await this.getWorkflowsFails({ workspaceId: this.workspace.id,  projetoId })
				.then((response) => {
					if(response.data.length !== 0) {
						this.optionsWorkflow = response.data.map((workflow) => {
							return {
								id: workflow.id,
								label: workflow.nome
							};
						});

					} else {
						this.selectedProject = '';
						this.optionsWorkflow = null;
						this.selectedWorkflow = null;

						this.$store.commit('activeAlert', {
							message: this.$locale.baseText('workflowDetails.patchFlowFail.flowElegibleEmpty'),
							status: 'alert',
						});
					}
			}).catch((error) => {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('workflowDetails.patchFlowFail.errorSearchFlow'),
					status: 'error',
				});
			});
		},
		async getProjects() {
			await this.restApi().getProjects(this.workspace.id, {size: 999}).then((response) => {
				this.optionsProject =  response.map((project) => {
					return {
						id: project.id,
						label: project.nome
					};
				});
			}).catch((error) => {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('Erro ao carregar a lista de projetos.', error?.notifications),
					status: 'error',
				});
			});
		},
		async getWorkflowFailureId() {
			try {
				const result = await this.getWorkflows({
          workspaceId: this.workspace.id,
          codigo: this.prevWorkflowError.workflowFalhaId,
        });

				this.selectedProject = this.findNameInObject(this.optionsProject, result?.data[0]?.projeto?.nome);
				this.currentProject = this.selectedProject.id;

				await this.getFails(this.selectedProject.id);

				this.selectedWorkflow = this.findNameInObject(this.optionsWorkflow, result?.data[0]?.nome);
				this.prevWorkflowError.selectedWorkflowId = this.findNameInObject(this.optionsWorkflow, result?.data[0]?.nome).id;
				this.currentWorkflow = this.selectedWorkflow.id;

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('Erro ao carregar os dados tente novamente em alguns instantes.'),
					status: 'error',
				});
			}
		},

		findNameInObject(objectList, name) {
			const objectFound = objectList.find(obj => obj.label === name);
			return objectFound || null; // Retorna null se o objeto não for encontrado
		},
	},
});
