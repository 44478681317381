
import { ATTACHED_EXECUTION_MODAL_KEY, RETRY_EXECUTION_MODAL_KEY, RESULT_EXECUTION_MODAL_KEY } from '../constants';
import Vue from 'vue';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import moment from 'moment';
import { executionLogPermission } from '@/components/mixins/permissions/executionLog';

import SelectFilter from '@/components/SelectFilter.vue';
import FilterTable from '@/components/FilterTable.vue';
import DatePicker from '@/components/DatePicker2.vue';
import FilterIcon from '@/components/FilterIcon.vue';
import FilterBar from '@/components/FilterBar.vue';
import Table from '@/components/Table.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import Modal from './Modal.vue';

export default mixins(
	restApi,
	workflowHelpers,
	executionLogPermission,
).extend({
	name: 'attachedExecutionModal',
	components: {
		Modal,
		Table,
		ScrollInfinite,
		SelectFilter,
		InteractionMenu,
		FilterTable,
		DatePicker,
		FilterIcon,
		FilterBar,
	},
	data() {
		return {
			modalBus: new Vue(),
			ATTACHED_EXECUTION_MODAL_KEY,
			RESULT_EXECUTION_MODAL_KEY,
			isLoadingTable: false,
			isLoadingPage: false,
			dataAttachedExecution: [],
			dataTableTemp: [],
			scrollInfinite: true,
			componentKey: 0,
			page: 1,
			pageSize: 10,
			filtering: false,
			applyedFilters: [],
			dataTableFilteredById: [],
			dataTableFilteredByName: [],
			lastFilterDate: '',
			lastFilterAttached: '',

			queryResults: [],
			queryDate: '',
			filtersActive: {},
			clearfiltering: false,

			maxheight: '60vh',
			tableTitle__1: 'executionLog.tableTitle__dateAndTime',
			tableTitle__2: 'executionLog.tableTitle__durations',
			tableTitle__3: 'executionLog.tableTitle__identifiers',
			tableTitle__4: 'executionLog.tableTitle__results',
			tableTitle__5: 'executionLog.tableTitle__flowsAndTheirProjects',
			tableTitle__6: 'executionLog.tableTitle__actions',
			tableTitle__7: 'executionLog.tableTitle__attached',

			executionLog__tableActionsButtonLeftDropdown: 'executionLog.executionLog__tableActionsButtonLeftDropdown',
			executionLog__tableActionsButtonRightDropdown: 'executionLog.executionLog__tableActionsButtonRightDropdown',
			executionLog__tableActionsRetryExecution: 'executionLog.executionLog__tableActionsRetryExecution',
		};
	},
	computed: {
		executionId() {
			return this.$store.getters.activeExecutionId;
		},
		resultText() {
      const length = this.queryResults.length;
      if (length === 1) {
        return this.queryResults[0];
      } else if (length === 0) {
        return 'Resultados';
      } else {
        return this.queryResults[0] + ' +' + (length - 1);
      }
    },
		intervalDateText() {
			if (this.queryDate === '') {
					return 'Datas e Horários';
			}

			const inicioFormatado = this.queryDate.inicio ? this.formatDate(this.queryDate.inicio) : 'Data Inicial';
			const fimFormatado = this.queryDate.fim ? this.formatDate(this.queryDate.fim) : 'Data Atual';

			return `De: ${inicioFormatado}\nAté: ${fimFormatado}`;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	async mounted () {
	},
	async created() {
		this.initPageExecution();
	},
	methods: {
		openResultModal(dataExecution) {
			if (dataExecution.resultado != 'Falha' && dataExecution.resultado != 'Alerta') {
				return;
			}
			this.$store.dispatch('ui/openModalWithData', { modalKey: RESULT_EXECUTION_MODAL_KEY, data: {executionId: dataExecution.id}});
		},
		async initPageExecution() {
			this.isLoadingTable = true;
			await this.getAttachedExecution();
			this.isLoadingTable = false;
		},

		async filterBySearch(search) {
			this.filtering = search.length <= 2 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length <= 2 ? '' : search);
		},
		async filterByResult(query) {
			const replaceQuery = query.map(item => item.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '_'))
			
			this.queryResults = query;
			this.addOrRemoveFilter(this.filtersActive, 'filterResults', replaceQuery);
			this.filtering = !this.queryResults.length;
		},
		async filterByDate(start, end) {
			if (start === 'Invalid date') start = '';
			if (end === 'Invalid date') end = '';

			this.queryDate = (start === '' && end === '') ? '' : { inicio: start, fim: end };
			this.filtering = (this.queryDate === '');

			this.addOrRemoveFilter(this.filtersActive, 'filterDate', this.queryDate);
		},

		async filterTable(filters) {
			try {
				this.isLoadingTable = true;

				const payload = {
					inicio: filters?.filterDate?.inicio,
					fim: filters?.filterDate?.fim,
					size: 10,
					searchNomeOuId: filters?.filterSearch,
					resultado: filters?.filterResults,
					excetoExecucoesAnexadas: false,

				};

				const dataFilter = await this.restApi().attachedExecutions(this.workspace.id, this.executionId, payload);
				await this.applyFilter(dataFilter, payload);
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoadingTable = false;
			}
		},
		async applyFilter(dataFiltered, filters) {
			this.page = 1;
			this.filtering = true;
			this.clearfiltering = true;
			this.scrollInfinite = true;
			this.dataAttachedExecution = dataFiltered;
			this.$store.commit('setFilters', filters);
		},

		clearFilter() {
			this.page = 1;
			this.filtering = false;
			this.clearfiltering = false;
			this.filtersActive = {};
			this.queryResults = [];
			this.queryDate = '';
			this.scrollInfinite = true;
			this.dataAttachedExecution = [];

			this.$store.commit('setFilters', this.filtersActive);
			this.getAttachedExecution();
    },
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},
		formatDuration(timestamp) {
			let hours = timestamp?.substring(0, 2) + 'h:';
			let minutes = timestamp?.substring(3, 5) + 'min:';
			let seconds = timestamp?.substring(6, 8) + 's';

			return hours + minutes + seconds;
		},


		closeDialog() {
			this.$store.commit('setActiveExecutionId', null);
			this.$store.commit('setFilters', {});
		},

		openModalRetryExecution(executionId) {
			this.$store.dispatch("ui/openModal", RETRY_EXECUTION_MODAL_KEY);
			this.$store.commit('setActiveExecutionId', executionId);
		},

		async loadExecutionsScroll() {
			if (this.dataAttachedExecution.length % this.pageSize === 0) {
				try {
					this.page++;

					const payload = {
						...this.$store.getters?.filters,
						size: this.pageSize,
						page: this.page,
					};

					const response = await this.restApi().attachedExecutions(this.workspace.id, this.executionId, payload);
					this.dataAttachedExecution.push(...response);
					this.dataTableTemp = this.dataAttachedExecution;

					if (response.length === 0) {
						this.scrollInfinite = false;
					}
				} catch (error) {
					console.error(error);
					this.scrollInfinite = false;
				}
			} else {
				this.scrollInfinite = false;
			}
		},

		accessDetailExecution (id) {
			this.$router.push({
				name: 'executionDetail',
				params: { id },
			});
			this.modalBus.$emit('close');
		},

		displayExecution(executionId: Object, e: PointerEvent) {
			if (e.metaKey || e.ctrlKey) {
				const route = this.$router.resolve({ name: 'ExecutionById', params: { id: executionId } });
				window.open(route.href, '_blank');

				return;
			} else {
				this.$router.push({
					name: 'ExecutionById',
					params: { id: executionId },
				});

				this.modalBus.$emit('close');

			}
		},
		async getAttachedExecution() {
			const payload = {
				size: this.pageSize,
				page: this.page,
				excetoExecucoesAnexadas: false,
			};
			

			await this.restApi().attachedExecutions(this.workspace.id, this.executionId, payload).then((response) => {
				this.dataAttachedExecution.push(...response);
				this.dataTableTemp = this.dataAttachedExecution;

				if (response.length !== 10) {
					this.scrollInfinite = false;
				}
			}).catch((error) => {
				this.modalBus.$emit('close');

				console.error(error);
			});
		},
	},
	beforeDestroy() {
		this.$store.commit('setFilters', {});
	},
});
